import mqtt from 'mqtt';

class MqttService {
  private static instance: MqttService | null = null;
  private client: mqtt.Client | null = null;
  private isSubscribed: boolean = false;

  private constructor() {
    this.client = this.createMqttClient();
  }

  public static getInstance(): MqttService {
    if (!MqttService.instance) {
      MqttService.instance = new MqttService();
    }

    return MqttService.instance;
  }
  
  private createMqttClient(): mqtt.Client {
//TODO
//for server hosted (nginx proxy)
//wss
//"andrii.ddns.net/broker"
//"andrii.ddns.net/broker"
//for server hosted (no nginx proxy)
//wss
//"andrii.ddns.net"
//"andrii.ddns.net"

//for local hosted
// ws
// host: "andrii.ddns.net", 
// port: 9001,
// hostname: "andrii.ddns.net",
const options = {
    protocol: process.env.REACT_APP_MQTT_PROTOCOL,          
    host: process.env.REACT_APP_HOST, 
    port: process.env.REACT_APP_MQTT_PORT,
    hostname: process.env.REACT_APP_HOST,
    clean: false,
    //username: "xxxxxxxxx",
    //password: "xxxxxxxx",
    keepalive: 6,
    // clientId uniquely identifies client
    // choose any string you wish
    // clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
    clientId: "mqttjs_" + Date.now().toString(),
  } as mqtt.IClientOptions;

    const client = mqtt.connect(options);

    return client;
  }

  public getClient(): mqtt.Client {
    //console.log("GET CLIENT");
    if (!this.client) {
      throw new Error("Mqtt client not initialized");
    }

    return this.client;
  }

  public subscribe() {
    if (!this.isSubscribed){
    const client = this.getClient();
    const topics = ["/offline", "/ping", "/sync", "/state/#", "/files/#", "/sys/#"];
    //const topics = ["/#"];
    // реализация подписки на топики
    // Добавлена проверка, чтобы подписка происходила только после установления соединения
        let str = localStorage.getItem('devices');
        if (str)
        {
          let devices = JSON.parse(str);
        
          devices.forEach((device : string) => {
  
            topics.forEach((topic) => {
              const fullTopic = device + topic;
              // Проверяем, подключен ли клиент перед подпиской
              client.subscribe(fullTopic, {qos: 1}, (error) => {
                if (error) {
                  console.log('Subscribe to topics error', error)
                  return
                }
                console.log('Subscribe to topic ' + fullTopic);
              });
            });
            // devNum = devNum + 1;
            this.publishMessage(device + "/desired/sync", "{\"mode\":\"all\"}", 1, false);
          });
          this.isSubscribed = true;
        }
        else {
          console.log("No devices in local storage");
        }
      }
    }
  

  public unsubscribe() {
    const client = this.getClient();
    const topics = ["/#"];
    // реализация подписки на топики
    
    console.log("UNSUBSCRIBE");

        let str = localStorage.getItem('devices');
        if (str)
        {
          let devices = JSON.parse(str);
        
          devices.forEach((device : string) => {
            topics.forEach((topic) => {
              const fullTopic  = device + topic;
              // Проверяем, подключен ли клиент перед отпиской
              if (client.connected) {
                client.unsubscribe(fullTopic, (error: Error) => {
                  if (error) {
                    console.log('Unsubscribe from topic error', error);
                    return;
                  }
                  console.log('Unsubscribe from topic ' + fullTopic);
                });
              }
            });
            // devNum = devNum + 1;
          });
        }
        else {
          console.log("No devices in local storage");
        }
  }

  public publishMessage(topic: string, message: string, qos: number, retain: boolean) {
    const client = this.getClient();

    const options: mqtt.IClientPublishOptions = {
      qos: qos as mqtt.QoS, // приведение к типу mqtt.QoS
      retain: retain as boolean
    };
  

    if (client.connected) {
      client.publish(topic, message, options, (error: Error | null | undefined) => {
        if (error) {
          console.error('Error publishing message:', error);
        } else {
          console.log('Topic:', topic);
          console.log('Message published successfully:', message);
        }
      });
    } else {
      console.error('Client is not connected. Cannot publish message.');
    }
  }

  // другие методы, если необходимо

  // Метод для освобождения ресурсов (вызывается при размонтировании)
  public cleanup() {
    if (this.client) {
      this.unsubscribe();
      this.client.end();
      this.client = null;
    }
  }
}

export default MqttService;